.service__wave {
    background-image: url("../../../images/assets/wave.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.service__menu__item--shadow {
    box-shadow: 0 12px 32px rgba(67, 78, 171, 0.25);
}

.promo__banner--shadow {
    box-shadow: 0 4px 32px rgba(67, 78, 171, 0.25);
}

.contact-us--shadow {
    box-shadow: 0 12px 32px rgba(67, 78, 171, 0.12);
}

.faq__button {
    background-position: center;
    background-repeat: no-repeat;
}

.accordion--open {
    background-image: url("../../../images/assets/icon-open.png");
}

.accordion--close {
    background-image: url("../../../images/assets/icon-minus.png");
}

.cat-dog-lineup {
    background-image: url("../../../images/assets/cat-dog-row.png");
    height: 24rem;
    width: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
}


.hero-swiper .swiper-slide {
    display: flex !important;
    justify-content: center !important;
}


.swiper-button-prev, .swiper-button-next {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 99px;
    background-color: white;
    width: 68px !important;
    height: 120px !important;
    position: absolute;
}

.swiper-button-prev {
    left: -32px !important;
    top: 50%;
    transform: translate(0, -32px);
    box-shadow: -4px 0 32px rgb(67 78 171 / 25%)
}

.swiper-button-next {
    right: -32px !important;
    top: 50%;
    transform: translate(0, -32px);
    box-shadow: 4px 0 32px rgb(67 78 171 / 25%)
}

.promo-swiper-container .swiper-button-prev::after {
    display: block;
    content: url("../../../images/assets/chevron-slider.png");
}

.promo-swiper-container .swiper-button-next::after {
    display: block;
    content: url("../../../images/assets/chevron-slider.png");
    transform: rotate(180deg);
}

.promo-swiper-container .swiper-pagination {
    position: absolute;
    bottom: -56px !important;
}


.promo-swiper-container .swiper-pagination.swiper-pagination-bullets {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.promo-swiper-container .swiper-pagination-bullet {
    width: 1rem;
    height: 1rem;
    background-color: #F0F0F0;
    opacity: 1;
    margin: 0 !important;
}

.promo-swiper-container .swiper-pagination-bullet-active {
    width: 2rem;
    border-radius: 99px;
    background-color: #7A85EF;
}


.testimony-swiper-container .swiper-pagination {
    position: absolute;
    bottom: -56px !important;
}

.testimony-swiper-container .swiper-pagination.swiper-pagination-bullets {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
}

.testimony-swiper-container .swiper-pagination-bullet {
    width: .75rem;
    height: .75rem;
    background-color: #BAC1FF;
    border-radius: 99px;
    opacity: 1;
    margin: 0 !important;
}

.testimony-swiper-container .swiper-pagination-bullet-active {
    background-color: #434EAB;
}

.testimony-swiper .swiper-slide {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
}


@media only screen and (max-width: 1024px) {

    .promo-swiper-container .swiper-pagination {
        bottom: -32px !important;
    }

    .cat-dog-lineup {
        height: 260px;
        background-position: center;
    }

    .promo-swiper-container .swiper-pagination-bullet {
        width: .5rem;
        height: .5rem;
    }

    .promo-swiper-container .swiper-pagination-bullet-active {
        width: 1rem;
    }
}
